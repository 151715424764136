





import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Logout extends Vue {
	private async mounted (): Promise<void> {
		await this.$store.commit('reset')
		this.$keycloak.clearToken()
		this.$keycloak.logout({ redirectUri: window.location.host })
	}
}
